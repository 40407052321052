.userRoleDataTableStyle .userRoleDataTableMaterialTable .MuiPaper-root.MuiPaper-elevation2.MuiPaper-rounded {
    background: transparent !important;
    border: none !important;
    -webkit-box-shadow: none !important;
    box-shadow: none !important;
}

.userRoleDataTableStyle .MuiTable-root .MuiTableHead-root tr th span {
    color: black !important;
    
}

.MuiTableCell-root {
    /* border-bottom: 1px solid rgba(0, 0, 0, 1) !important; */
    border-bottom: 1px solid rgb(20 188 206 / 42%) !important;
}

.MuiInput-underline:before,
.MuiInput-underline:after {
    /* border-bottom: 1px solid #000 !important; */
    border-bottom: 1px solid rgb(20 188 206 / 42%) !important;
}

.MuiTableCell-body {
    color: #000000 !important;
}

.MuiInputBase-input {
    color: #000000 !important;
}

.dataTableContainer .MuiTypography-root {
    color: #000000 !important;
}

.userRoleDataTableStyle .MuiSvgIcon-fontSizeSmall {
    fill: #000000 !important;
}

.userRoleDataTableStyle .MuiIconButton-root {
    color: #ffffff !important;
    padding: 0 !important;
    outline: none !important;
    outline: 0px auto -webkit-focus-ring-color !important;
}

.userRoleDataTableStyle .MuiIconButton-root:focus {
    outline: none !important;
    outline: 0px auto -webkit-focus-ring-color !important;
}

.userRoleDataTableStyle .MuiButtonBase-root.MuiButton-root.MuiButton-contained:focus {
    outline: none !important;
    outline: 0px auto -webkit-focus-ring-color !important;
}
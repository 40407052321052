.memberAddButtonStyle{
	margin-top: 10px !important;
	background: #0099FF !important;
}
.memberDataTableStyle .MuiGrid-root.MuiGrid-container.MuiGrid-spacing-xs-1 .MuiGrid-root.MuiGrid-item.MuiGrid-grid-xs-12.MuiGrid-grid-sm-8 .memberDataTableAddButton {
    background: #0099FF !important;
    color: white !important;
}

.memberDataTableStyle .MuiGrid-root.MuiGrid-container.MuiGrid-spacing-xs-1 .MuiGrid-root.MuiGrid-item.MuiGrid-grid-xs-12.MuiGrid-grid-sm-8 form {
    padding: 0 !important;
    margin: 0 !important;
}

.memberDataTableAddDialog .MuiDialog-container.MuiDialog-scrollPaper .MuiDialogTitle-root {
    background: white !important;
}

.memberDataTableAddDialog .MuiDialog-container.MuiDialog-scrollPaper .MuiDialogContent-root {
    background: white !important;
    color: black !important;
}

.memberDataTableAddDialog .MuiDialog-container.MuiDialog-scrollPaper .MuiDialogActions-root.MuiDialogActions-spacing {
    background: white !important;
}

.memberDataTableMaterialTable .MuiPaper-root.MuiPaper-elevation2.MuiPaper-rounded {
    background: transparent !important;
    border: none !important;
    -webkit-box-shadow: none !important;
    box-shadow: none !important;
}

.memberDataTableMaterialTable .MuiTableCell-root {
    border-bottom: 1px solid rgb(20 188 206 / 42%) !important;
    border-top: 1px solid rgb(20 188 206 / 42%) !important;
}
.MuiFormLabel-root.MuiInputLabel-root.MuiInputLabel-formControl.MuiInputLabel-animated.MuiInputLabel-outlined
{
	marging:20px;
}
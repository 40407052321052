.applicationDetailsStyle {
    width: 100% !important; 
    min-height: 500px !important;
}

.applicationDetailsStyle form {
    padding: 0 !important; 
    margin: 0 !important;
}

.applicationDetailsStyle form>div {
    width: 100% !important;
}

.applicationDetailsStyle form>div .MuiBox-root>button.MuiButtonBase-root.MuiIconButton-root.saveChanges,
.applicationDetailsStyle form>div .MuiBox-root>button.MuiButtonBase-root.MuiIconButton-root.cancelChanges,
.applicationDetailsStyle form>div .MuiBox-root>button.MuiButtonBase-root.MuiIconButton-root.edit {
    float: right !important; 
    padding: 0 !important;
}

.applicationDetailsStyle form>div .MuiBox-root>button.MuiButtonBase-root.MuiIconButton-root.saveChanges .MuiIconButton-label .MuiSvgIcon-root,
.applicationDetailsStyle form>div .MuiBox-root>button.MuiButtonBase-root.MuiIconButton-root.edit .MuiIconButton-label .MuiSvgIcon-root {
    /* fill: white !important; */
    fill: black !important;
}

.applicationDetailsStyle form>div .MuiBox-root>button.MuiButtonBase-root.MuiIconButton-root.cancelChanges .MuiIconButton-label .MuiSvgIcon-root {
    fill: red !important;
    opacity: 0.8 !important;
}

.applicationDetailsStyle form label .applicationDetailsTitle {
    text-transform: uppercase !important;
    color: black !important;
}

.applicationDetailsStyle form label .applicationDetailsInfo {
    /*text-transform: capitalize !important;*/
    /* color: white !important; */
    color: black !important;
    word-break: break-all !important;
     text-transform: capitalize;
    
}

.applicationDetailsStyle form label .applicationDetailsInfoUrl {
    /*text-transform: capitalize !important;*/
    /* color: white !important; */
    color: black !important;
    word-break: break-all !important;
     text-transform: lowercase !important;
    
}

.applicationDetailsStyle form label .applicationDetailsClientIdInfo {
    /* color: white !important; */
    color: black !important;
}

.applicationDetailsStyle form .applicationDetailsClientCredentials .MuiBox-root>button.MuiButtonBase-root.MuiIconButton-root {
    float: right !important;
    padding: 0 !important;
}

.applicationDetailsStyle form .applicationDetailsClientCredentials .MuiBox-root>button.MuiButtonBase-root.MuiIconButton-root .MuiIconButton-label .MuiSvgIcon-root {
    /* fill: white !important; */
    fill: black !important;
}


.addApplicationBtn {
    color: #ffffff !important;
  /*  border: 1px solid #000000 !important;*/
    outline: none !important;
}

.applicationDetailsStyle .MuiFormLabel-root.MuiInputLabel-root.MuiInputLabel-formControl.MuiInputLabel-animated {
    color: rgb(101, 101, 101) !important;
    font-weight: 400 !important;
    font-size: small !important;
}

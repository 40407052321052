/*.addApplicationStyle .MuiGrid-root.MuiGrid-container.MuiGrid-justify-xs-center {
  padding-top: 2% !important;
}*/

.addApplicationStyle .addApplicationBlock {
  width: 100% !important;
  min-height: 600px !important;
  /* background: #00506E !important; */
  padding: 0 !important;
  box-shadow: 0px 4px 4px #00000054 !important;
  border-radius: 0px 0px 3px 3px !important;
  opacity: 1 !important;
  margin-top: 7% !important;
}

.addApplicationStyle .addApplicationBlock>div {
  background: #00637D !important;
  background-repeat: no-repeat !important;
  height: 80px !important;
  background-size: 100% !important;
}

.addApplicationStyle .addApplicationBlock form {
  padding: 5% !important;
  background-color: #FFFFFF !important;
}

.addApplicationStyle .addApplicationBlock form .MuiFormControl-root.MuiTextField-root {
  width: 100% !important;
  color: white !important;
  margin-bottom: 10px !important;
}

.addApplicationStyle .addApplicationBlock form .MuiFormControl-root.MuiTextField-root .MuiInputBase-input.MuiInput-input {
  color: #000000 !important;
     text-transform: lowercase;  
}

.addApplicationStyle .addApplicationBlock form .MuiFormControl-root.MuiTextField-root .MuiInput-underline:after,
.addApplicationStyle .addApplicationBlock form .MuiFormControl-root.MuiTextField-root .MuiInput-underline:before,
.addApplicationStyle .addApplicationBlock form .MuiFormControl-root .MuiInput-underline:after,
.addApplicationStyle .addApplicationBlock form .MuiFormControl-root .MuiInput-underline:before {
  border-bottom: 1px solid rgb(20 188 206 / 42%) !important;
}

.addApplicationStyle .addApplicationBlock form .MuiFormControl-root {
  width: 100% !important;
}

.addApplicationStyle .addApplicationBlock form .MuiFormControl-root label {
  font-size: small !important;
  color: rgb(101, 101, 101) !important;
  font-weight: 400 !important;
}

.addApplicationStyle .addApplicationBlock form .MuiFormControl-root .MuiFormHelperText-root {
  color: rgb(244, 67, 54) !important;
}

input::-webkit-input-placeholder {
  color: rgb(101, 101, 101) !important;
  }
  
/* Firefox < 19 */
input:-moz-placeholder {
  color: rgb(101, 101, 101) !important;
}
input:focus:-moz-placeholder {
  color: rgb(101, 101, 101) !important;
}

/* Firefox > 19 */
input::-moz-placeholder {
  color: rgb(101, 101, 101) !important;
}
input:focus::-moz-placeholder {
  color: rgb(101, 101, 101) !important;
}

/* Internet Explorer 10 */
input:-ms-input-placeholder {
  color: rgb(101, 101, 101) !important;
}
input:focus:-ms-input-placeholder {
  color: rgb(101, 101, 101) !important;
}
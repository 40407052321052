ol {
    counter-reset: item;
    font-size: small;
    padding: 0 0 0 5%;
}
ol li {
    display: block;
    position: relative;
}
ol li:before {
    content: counters(item, ".")".";
    counter-increment: item;
    position: absolute;
    margin-right: 100%;
    right: 5px; /* space between number and text */
}

@media (max-width: 228px) {
	.devFooterStyle footer ul li a {
        padding: 0.5rem !important;
        font-size: 10px !important;
	}
}
@media (max-width: 375px) {
	.devFooterStyle footer ul li a {
        padding: 0.5rem !important;
        font-size: 10px !important;
	}
}
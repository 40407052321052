.emailVerificationForm .MuiFormControl-root.MuiTextField-root label {
    color: rgb(101, 101, 101) !important;
    font-weight: 400 !important;
    font-size: small !important;
    text-transform: uppercase;
    opacity: 1 !important;
}

.emailVerificationForm .iconGrid .MuiSvgIcon-root {
    position: relative;
    top: 12px;
    fill: #0098FF5C;
}

@media screen and (max-width: 400px) {
    .emailVerificationForm .iconGrid .MuiSvgIcon-root {
        margin-left: -10px;
    }
}

.registerStyle .registerStyleItem {
    padding-top: 60px;
}

.registerStyle .registerStyleItem .registerStyleCardSubHeader {
    color: #3E3E3E !important;
    margin-top: 5% ;
    font: normal normal medium 20px/24px Montserrat !important;
}

.registerStyle .registerStyleItem form .MuiGrid-root.MuiGrid-container.MuiGrid-spacing-xs-1.MuiGrid-align-items-xs-flex-end {
    margin-top: 2% !important;
}

.registerStyle .registerStyleItem form .MuiCardContent-root .MuiGrid-root.form-label.MuiGrid-item .MuiSvgIcon-root {
    fill: #0098FF5C !important;
}

.registerStyle .registerStyleItem form .MuiCardContent-root .MuiFormControl-root.MuiTextField-root {
    color: black !important;
}

.registerStyle .registerStyleItem .MuiCardContent-root hr {
    color: #cad0c4 !important;
    width: 50% !important;
}

.registerForm .MuiFormControl-root.MuiTextField-root label {
    color: rgb(101, 101, 101) !important;
    font-weight: 400 !important;
    font-size: small !important;
    text-transform: uppercase;
    opacity: 1 !important;
}

.registerForm .iconGrid .MuiSvgIcon-root {
    position: relative;
    top: 17px;
    fill: #0098FF5C;
}

@media screen and (max-width: 400px) {
    .registerForm .iconGrid .MuiSvgIcon-root {
        margin-left: -10px;
    }
}

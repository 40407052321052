.MuiInputBase-input {
    font: inherit;
    border-bottom: 1px solid;
    width: 100%;
    border: 0;
    height: 1.1876em;
    margin: 0;
    display: block;
    padding: 6px 0 7px;
    min-width: 0;
    background: none;
    box-sizing: content-box;
    animation-name: mui-auto-fill-cancel;
    letter-spacing: inherit;
    animation-duration: 10ms;
    -webkit-tap-highlight-color: transparent;
}

.dataTableContainer .MuiTypography-root {
    color: white !important;
}

.MTableToolbar-title {
    overflow: visible !important; 
}

.MTableToolbar-actions {
    color: white !important;
}

.MuiInputBase-input{
  color:white !important;
}

.input-field .MuiInputBase-input{
     color:black !important;
}
.MuiMenu-list {
    outline: 0;
    background: white !important;
    color: black !important;
}

.MuiTableCell-body {
     color: white !important;
}

.MuiTableCell-paddingCheckbox:last-child {
    background: transparent !important;
    color:white
}

.MuiTableRow-root {
    font-size: small;
}

.MuiToolbar-gutters {
    padding-left: 0 !important;
}

.MuiFormControl-marginNormal {
    margin-top: 0px !important;
    margin-bottom: 8px !important;
}

.MuiAutocomplete-inputRoot[class*="MuiOutlinedInput-root"] .MuiAutocomplete-input:first-child {
    color: black !important;
}

.MuiInputLabel-outlined.MuiInputLabel-shrink {
    color: black !important;
}

.MuiSvgIcon-fontSizeSmall {
    fill: white !important;
}

.addApplicationBtn{
    border-color:white !important;
    color:white !important
}

.submitBtn{
    background: #40B3FF 0% 0% no-repeat padding-box !important;
    box-shadow: 0px 3px 3px #00000033 !important;
    border-radius: 3px !important;
    opacity: 1 !important;
}
.submitBtn:hover{
    background: #0099FF 0% 0% no-repeat padding-box !important;
}

.timeLine:hover {
  cursor: pointer;
}

.timeLine {
  cursor: -webkit-grab;
  font:'Montserrat,medium';
  font-size:"20px";
  margin-right:"5px"
}

.input-field .MuiInput-underline:before {
    border-bottom: 1px solid #AAE5FF  !important;
}

button:focus {
    outline: none !important;
    outline: 0px auto -webkit-focus-ring-color !important;
}

.appName{
	word-wrap: break-word !important;
}

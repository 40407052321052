/* .custom-nav-link {
    color: #fff;
    border: none;
    font-size: 0.9375rem;
    font-weight: 400;
    margin-right: 8px;
    text-transform: none;

    padding: 6px 12px;
    overflow: hidden;
    position: relative;
    font-size: 0.875rem;
    max-width: 264px;
    min-width: 72px;
    box-sizing: border-box;
    min-height: 48px;
    text-align: center;
    flex-shrink: 0;
    font-family: "Roboto", "Helvetica", "Arial", sans-serif;
    font-weight: 500;
    line-height: 1.75;
    white-space: normal;
    letter-spacing: 0.02857em;
    text-transform: uppercase;

    position: relative;
    top: 12px;
} */


.custom-nav-link {
    color: white;
    text-decoration: none;
    position: relative; 
    top: 12px;
}

.custom-nav-link:hover {
    text-decoration: none;
}



@media (max-width: 768px) {
	.custom-nav-link {
        text-align: center;
        margin-right: 8px;
        padding: 6px 12px;
        min-height: 48px;
    }
}

@media (min-width: 600px) {
	.customUnsubscribeHeader a {
	    min-width: 50px;
	}
}
#successPage,#failurePage {
	 position: relative;
    height: 100vh;
	background-color: #4950570d;
    height: 400px;
}
#successPage .successPage,#failurePage .failurePage {
 position: absolute;
    left: 50%;
    /* width: 94%; */
    top: 50%;
    justify-content: space-between;
    -webkit-transform: translate(-50%, -50%);
    -ms-transform: translate(-50%, -50%);
    transform: translate(-50%, -50%);
}
.successmsg, .failuremsg {
   margin: 2% !important;
}
.successmsg .message>h3,.failuremsg .message>h3{
 font-weight: 400;
}
.successPage a,.failurePage a {
	font-family: 'Maven Pro', sans-serif;
	font-size: 14px;
	text-decoration: none;
	text-transform: uppercase;
	background: #189cf0;
	display: inline-block;
	padding: 16px 38px;
	border: 2px solid transparent;
	border-radius: 40px;
	color: #fff;
	font-weight: 400;
	-webkit-transition: 0.2s all;
	transition: 0.2s all;
}
.successPage,.failurePage {
	 max-width: 64%;
    /* max-width: 920px; */
    width: 100%;
    line-height: 1.4;
    text-align: center;
    padding-left: 32px;
    padding-right: 15px;
}

.bannerContent {
	padding: 4%;
}

.custom-left-arrow {
	position: absolute !important;
	left: 8px;
	z-index: 1;
	border: 3px solid #00546e;
	border-width: 0 3px 3px 0;
	display: inline-block;
	padding: 10px;
	opacity: 1;
	cursor: pointer;
	transform: rotate(135deg);
}

.custom-right-arrow {
	position: absolute !important;
	right: 10px;
	z-index: 1;
	border: 3px solid #00546e;
	border-width: 0 3px 3px 0;
	display: inline-block;
	padding: 10px;
	opacity: 1;
	cursor: pointer;
	transform: rotate(-45deg);
}

.react-multi-carousel-dot-list {
	position: relative;
	/* bottom: -4px; */
	display: flex;
	left: 0;
	right: 0;
	justify-content: center;
	margin: auto;
	padding: 0;
	margin: 0;
	list-style: none;
	text-align: center;
}

.paperContainer {
	top: "128px";
	left: "0px";
	width: "100%";
	height: "300px";
	background-size: "cover";
	opacity: 1;
	background-repeat: "no-repeat";
	background-size: "cover";
	background-position: "center center";
}

.orgImage .MuiAvatar-img {
	padding-left: 1%;
	padding-top: 0%;
	padding-bottom: 0%;
	width: 78px !important;
	height: 77px !important;
	object-fit: cover;
	text-align: center;
	text-indent: 10000px;
}

.MuiAvatar-img {
	padding-left: 2%;
	padding-top: 0%;
	padding-bottom: 0%;
	width: 89px !important;
	height: 77px !important;
	object-fit: cover;
	text-align: center;
	text-indent: 10000px;
}


/* .avatarPad .makeStyles-root-53 > * {
    margin: 6% 10% 2% 0%;
} */

.makeStyles-large-131 {
	width: 54%;
}

.paperContainer {
	top: "128px";
	left: "0px";
	width: "100%";
	height: "365px";
	opacity: "1";
	resize: cover !important;
	background-repeat: no-repeat !important;
	background-size: cover !important;
	background-position: center center !important;
}

.avatarContainer {
	background-size: cover;
	background-repeat: no-repeat;
	background-position:center;
	margin-bottom: 100px;
	-webkit-filter: drop-shadow(1px 1px 5px rgba(0, 0, 0, 0.4));
	filter: drop-shadow(1px 1px 5px rgba(0, 0, 0, 0.4));
	
	
}
.cta-avtar-img {
	
	margin: auto;
	margin-top:50px;
	background: #FFFFFF 0% 0% no-repeat padding-box;
	box-shadow: 0px -1px 20px #00000026 ;
	opacity: 1;

}
@media (min-width:960px) {
	.avatarContainer .MuiAvatar-circle {
		width: 132px;
		height: 132px;
	}
}
@media (max-width:959px) {
	.avatarContainer .MuiAvatar-circle {
		width: 128px;
		height: 128px;
	}
}

@media (max-width:768px) {
	.avatarContainer {
		background-position: -30px -15px;
		filter: drop-shadow(0);
	}
	
}

.dividerstyle {
	border: "1px solid #707070";
	opacity: "1";
	margin: "0% 7% 0% 7%";
}

.footerContainer {
	text-align: center !important;
	padding: 2% 0% !important;
	font: normal normal normal 24px/29px Montserrat !important;
	letter-spacing: 0.72px !important;
	color: #0086FB !important;
	opacity: 1 !important;
	background: rgb(248 248 248) 0% 0% no-repeat padding-box !important;
	box-shadow: 0px 1px 5px #00000029 !important;
	width: "100%";
}
footer li a {
 padding: 0.9275rem !important;
}

.text-style {
	text-align: center !important;
	font: normal normal normal 14px/19px Roboto !important;
	letter-spacing: 0px !important;
	color: #373737 !important;
	float: right !important;
	opacity: 1 !important;
}

.card-image {
	text-align: center !important;
	display: block !important;
	margin: 0 auto !important;
}

.divider-style {
	border-bottom: 1px solid #A4A4A4 !important;
	margin-top: 50px;
	display: block;
}

.fontStyle {
	font: normal normal normal 24px/29px Montserrat !important;
}

.text-padding-style {
	padding-left: 4% !important;
}

.txtLabel {
	font: normal normal Montserrat !important;
	letter-spacing: 0.48px !important;
	color: #FFFFFF !important;
	opacity: 1 !important;
	font-weight: lighter !important;
	text-align: center;
}

.txt-label-underline {
	text-decoration: underline;
}

.txtLabel::after {
	border-bottom: 1px solid rgb(20 188 206 / 42%) !important;
	color: black !important;
}

.shape {
	background-repeat: no-repeat !important;
}

.servicesCarousel {
	padding: 1% 9% 2% 9%;
}

.servicesCarousel .carousel-container {
	min-height: 310px;
}


.react-multi-carousel-track {
  padding: revert !important;
}

.cardContainer {
	background: transparent linear-gradient(360deg, #3EC8FF 0%, #FEFEFE 100%) 0% 0% no-repeat padding-box !important;
	box-shadow: 0px 4px 6px #8B8B8BA1 !important;
	border-radius: 10px !important;
	max-width: 75% !important;
	min-width: 30% !important;
	text-align: center !important;
	font: normal normal normal 12px/6px Lato !important;
	letter-spacing: 0px !important;
	color: #A5DBFF !important;
	opacity: 1 !important;
	height: 270px !important;
	cursor: pointer;
}

.card-label {
	text-align: center !important;
	font: normal normal 600 16px/19px Lato !important;
	letter-spacing: 0.32px !important;
	color: #0099FF !important;
	opacity: 1 !important;
	min-height: 40px !important;
}

.card-Desc {
	font: normal normal normal 15px/21px Roboto !important;
	letter-spacing: 0.32px !important;
	color: #000000 !important;
	opacity: 1 !important;
	min-height: 65px !important
}

.label-style {
	padding-left: 6% !important;
	padding-bottom: 2% !important;
	letter-spacing: 1.2px;
	color: #FFFFFF;
	opacity: 1
}

.brandLabel {
	font: normal normal Montserrat !important;
	font-weight: 600 !important;
	font-size: x-large !important;
}

.labelText-style {
	padding: 4% 0% !important;
	font: normal normal 300 30px/39px Roboto !important;
	letter-spacing: 0px !important;
	color: #FFFFFF !important;
}

.btn-style {
	background: #FFFFFF 0% 0% no-repeat padding-box !important;
	box-shadow: 0px 3px 5px #FFFFFF4D !important;
	border: 1px solid #FFFFFF !important;
	text-align: center !important;
	color: #0099FF !important;
}

.btn-style:hover {
	background: transparent !important;
	box-shadow: 0px 3px 5px #FFFFFF4D !important;
	border: 1px solid #FFFFFF !important;
	text-align: center !important;
	color: #FFFFFF !important;
}

.labeltxt-style {
	padding: 1% 0% !important;
	font: normal normal 400 30px/39px Roboto !important;
	letter-spacing: 0.03px !important;
	color: #FFFFFF !important;
	opacity: 1 !important;
}


.featureDataTableStyle .MuiPaper-root.MuiPaper-elevation2.MuiPaper-rounded {
	background-color: transparent !important;
	/* border: none !important; */
	box-shadow: none !important;
}

.featureDataTableStyle .MuiTableHead-root tr th span div,
.featureDataTableStyle .MuiTableHead-root tr th span {
	color: black !important;
}

.featureDataTableStyle .MuiInputBase-input.MuiInput-input.MuiInputBase-inputAdornedStart.MuiInputBase-inputAdornedEnd,
.featureDataTableStyle .MuiTable-root .MuiTableHead-root tr th,
.featureDataTableStyle .MuiTable-root .MuiTableBody-root tr td,
.featureDataTableStyle .MuiTable-root .MuiTableBody-root tr td .MuiInputBase-input.MuiInput-input,
.featureDataTableStyle .MuiTable-root .MuiTableFooter-root tr td {
	/* border-bottom: 1px solid black !important; */
	border-bottom: 1px solid rgb(20 188 206 / 42%) !important;
	color: black !important;
}

.featureDataTableStyle .MuiTable-root .MuiTableFooter-root tr td .MuiSelect-root.MuiSelect-select.MuiTablePagination-select.MuiSelect-selectMenu.MuiInputBase-input div,
.featureDataTableStyle .MuiTable-root .MuiTableFooter-root tr td .MuiTypography-root.MuiTypography-caption,
 
.featureDataTableStyle .MuiInputAdornment-root.MuiInputAdornment-positionStart .MuiSvgIcon-root.MuiSvgIcon-fontSizeSmall {
	color: black !important;
}

.featureDataTableStyle .MuiInput-underline:before {
	/* border-bottom: 1px solid !important; */
	border-bottom: 1px solid rgb(20 188 206 / 42%) !important;
}

.featureDataTableStyle .MuiSvgIcon-fontSizeSmall {
    fill: #000000 !important;
}

.featureDataTableStyle .MuiIconButton-root {
    color: #ffffff !important;
	padding: 0 !important;
	outline: none !important;
    outline: 0px auto -webkit-focus-ring-color !important;
}

.MuiInput-underline.Mui-error:after{
	border-bottom-color: #36aef4c9 !important;
}

 
@font-face {
    font-family: 'Roboto';
    src: url('./fonts/roboto/Roboto-Regular.ttf');
}
.header1
{
    color: white;
    font-size: 44px;
    text-align: left;
    margin-top: 50px;
    margin-left: 50px;
    font-family: 'Roboto';
}

.header2{
    color: white;
    font-weight: 300;
    text-align: left;
    margin-left: 50px;
    font-family: 'Roboto';
    font-weight: lighter !important;
}
.header3{
    font-size:24px;
    text-transform: capitalize;
    color: #303030;
    font-weight: 400;
    margin-bottom: 20px; 
    margin-top: 10px;
    text-align: center;
    letter-spacing: .03px!important;
    font-family: 'Roboto';
  }
  .subheading
  {
    font-size: 18px;
    font-family: 'Roboto';
    font-weight: 400;
    line-height: 1.5;
    letter-spacing: 0.00938em;
    color: #727272;
  }
  .advantages
{
    font-size: 16px;
    line-height: 1.375em;
    color: #727272;
    font-weight: 300;
    margin-bottom: 30px;
    text-align: center !important;
    font-family: Roboto;     
}
.header4
{
    font-size:24px;
    text-transform: capitalize;
    color: #303030;
    font-weight: 400;
    margin-bottom: 20px; 
    margin-top: 20px;
    text-align: left;
    letter-spacing: .03px!important;
    font-family: 'Roboto';
}
.subheading2 {
    font-size: 18px;
    font-family: Roboto;
    font-weight: 400;
    line-height: 1.5;
    letter-spacing: 0.00938em;
    color: #737373;
    text-align: left! important;
    margin: 10px 0;
}
.businessimage
{
    height: auto;
    width: 1400px;
}
.logicalimage
{
    border: 1px solid #ccc;
    font-weight: 300;
    text-align: center;
}
.logicalimage1
{
    text-align: center;
}
.containertext {
    padding-top: 5px;
    padding-bottom: 5px;
    margin-right: auto;
    margin-left: auto;
}
.servicestext
{
   text-align: center;
   font-family: 'Roboto';
}
.devtext
{
    font-size: 20px;
    text-transform: uppercase;
    color: #757474;
    font-weight: 500;
    margin-bottom: 30px;
    text-align: center;
    margin-top: 20px;
    font-family: 'Roboto';
}
.ecotext
{
    font-size: 17px;
    line-height: 1.375em;
    color: #757474;
    font-weight: 400;
    margin-bottom: 30px;
    text-align: center;
    font-family: Roboto;
}
.ecotext1
{
    font-size: 17px;
    line-height: 1.375em;
    color: #757474;
    font-weight: 400;
    margin-bottom: 30px;
    text-align: left;
    font-family: Roboto;
}
.sdlimg
{
    width: 563px;
    height: 570px;
}
.jumbotron
{
    background: linear-gradient(90deg, rgba(0,153,255,1) 0%, rgba(20,41,124,1) 50%, rgba(65,10,109,0.7483368347338936) 100%), #008077 url(../../images/Picture80.png) no-repeat right top;
    background-size: auto;
    color: #fff;
    padding: 100px 25px;
    font-family: -apple-system,BlinkMacSystemFont,Segoe UI,Roboto,Helvetica Neue,Arial,Noto Sans,sans-serif;
    height: 360px;
}
.paper {
    padding:10px;
    text-align: center;
    color: black;
  }
  .imgcenter
  {
text-align: center;
  }
  .servicesCarousel
  {
      background-color: #f6f6f6
  }
.auth
{
    margin-top: -400px;
}
hr.hrline {
    border: 2px solid #00b3bb;
    width:100px;
    margin-top: 0.3rem;
    
  }
/* @media (max-width: 600px) {
    .businessimage {
        height: auto;
        width: 300px;
    }
}
@media (max-width: 960px) {
    .businessimage {
        height: auto;
        width: 400px;
    }
}
@media (max-width: 1280px) {
    .businessimage {
        height: auto;
        width: 800px;
    }
}
@media (max-width: 1440px) {
    .businessimage {
        height: auto;
        width: 1000px;
    }
} */
@media (max-width: 2100px) {
    .businessimage {
        height: auto;
        width: 1000px;
    }
}


.changePasswordSection .changePasswordBlock .changePasswordHeader {
	background: #00637D !important;
	height: 88px;
}

.changePasswordSection .changePasswordBlock .changePasswordBody {
	/* background: #00506E !important; */
	background: #FFFFFF !important;
	box-shadow: 0px 3px 6px rgba(0, 0, 0, 0.16);
}

.changePasswordSection .MuiInputBase-input {
	color: #000000 !important;
}

.changePasswordSection .input-label.MuiFormLabel-root.Mui-focused,
.changePasswordSection .MuiFormLabel-root.MuiInputLabel-root.input-label {
  color:rgb(101, 101, 101) !important;
  font-weight: 400 !important;
  font-size: small;
  

}

.changePasswordSection .input-label+.MuiInput-underline:before,
.changePasswordSection .input-label+.MuiInput-underline:after {
	/* border-bottom: 1px solid #FFFFFF66 !important; */
	border-bottom: 1px solid rgb(20 188 206 / 42%) !important;
}

.changePasswordSection .update-button {
	background: #40B3FF !important;
}

.changePasswordSection .update-button:hover {
	background: #0099ff !important;
}

.profileSection .profileBlock .profileHeader {
	background: #00637D !important;
	height: 88px;
}

.profileSection .profileBlock .profileBody {
	/* background: #00506E !important; */
	background: #FFFFFF !important;
	box-shadow: 0px 3px 6px rgba(0, 0, 0, 0.16);
	padding-top: 40px !important;
}

.profileSection .MuiInputBase-input {
	/* color: #FFF !important; */
	color: #000000 !important;
}

.profileSection .input-label.MuiFormLabel-root.Mui-focused,
.profileSection .MuiFormLabel-root.MuiInputLabel-root.input-label {
	color: rgb(101, 101, 101) !important;
	font-weight: 400 !important;
}

.profileSection .input-label+.MuiInput-underline:before,
.profileSection .input-label+.MuiInput-underline:after {
	/* border-bottom: 1px solid #FFFFFF66 !important; */
	border-bottom: 1px solid rgb(20 188 206 / 42%) !important;
}

.profileSection .update-button {
	background: #40B3FF !important;
}

.profileSection .update-button:hover {
	background: #0099FF !important;
}

.profilePageStyle .profilePageBlock form .MuiFormControl-root label,
.changePasswordStyle .changePasswordBlock form .MuiFormControl-root label {
	font-size: small !important;
}

input::-webkit-input-placeholder {
	color: rgb(101, 101, 101) !important;
}

input:focus::-webkit-input-placeholder {
	color: rgb(101, 101, 101) !important;
}


/* Firefox < 19 */

input:-moz-placeholder {
	color: rgb(101, 101, 101) !important;
}

input:focus:-moz-placeholder {
	color: rgb(101, 101, 101) !important;
}


/* Firefox > 19 */

input::-moz-placeholder {
	color: rgb(101, 101, 101) !important;
}

input:focus::-moz-placeholder {
	color: rgb(101, 101, 101) !important;
}


/* Internet Explorer 10 */

input:-ms-input-placeholder {
	color: rgb(101, 101, 101) !important;
}

input:focus:-ms-input-placeholder {
	color: rgb(101, 101, 101) !important;
}

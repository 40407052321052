/* .featureDataTable{
	background: transparent !important;
	border:none !important;
	box-shadow:none !important;
} */

.featureDataTableStyle .MuiPaper-root.MuiPaper-elevation2.MuiPaper-rounded {
	background-color: transparent !important;
	box-shadow: none !important;
}



 
    










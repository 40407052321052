.fullWidthTabsStyle {
    border: 1px solid #e0e0e0 !important;
    box-shadow: 0px 2px 1px -1px rgba(0,0,0,0.2), 0px 1px 1px 0px rgba(0,0,0,0.14), 0px 1px 3px 0px rgba(0,0,0,0.12) !important;
}

.fullWidthTabsStyle .tabPanel {
    padding: 0px !important;
    margin: 0px !important;
    min-height: 500px !important;
    background: white !important;
    border: 1px #e0e0e0;
}


.applicationHomeStyle .MuiGrid-root .MuiGrid-root.MuiGrid-container {
    /* background-color: #00506E !important; */
    margin-top: 15px !important;
    /* padding-top: 3% !important; */
}

.applicationHomeStyle .MuiGrid-root .MuiGrid-root.MuiGrid-container .MuiGrid-root.MuiGrid-item.MuiGrid-grid-xs-true {
    background-color: white !important;
}

.applicationHomeStyle .MuiGrid-root .MuiGrid-root.MuiGrid-container .MuiGrid-root.MuiGrid-item.MuiGrid-grid-xs-12.MuiGrid-grid-sm-8 {
    border: 2px !important;
    -webkit-box-shadow: none !important;
    box-shadow: none !important;
}
body {
  overflow-x: hidden !important;
}

.App {
  display: flexbox;
}

.radio-button {
  height: auto !important;
  width: auto !important;
}

.navbar {
  background-color: #e3f2fd;
}

.fa.fa-edit {
  color: #18a2b9;
}

.list-group-item.delete:hover {
  cursor: -webkit-grab;
  background-color: pink;
}

.list-group-item.update:hover {
  cursor: -webkit-grab;
  background-color: gainsboro;
}

.list-group-item.board:hover {
  cursor: -webkit-grab;
  background-color: gainsboro;
}

.fa.fa-minus-circle {
  color: red;
}

.landing {
  position: relative;
  /* background: url("../img/showcase.jpg") no-repeat; */
  background-size: cover;
  background-position: center;
  height: 100vh;
  margin-top: -24px;
  margin-bottom: -50px;
}

.landing-inner {
  padding-top: 80px;
}

.dark-overlay {
  background-color: rgba(0, 0, 0, 0.7);
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
}

.card-form {
  opacity: 0.9;
}

.latest-profiles-img {
  width: 40px;
  height: 40px;
}

.form-control::placeholder {
  color: #bbb !important;
}

.WithStyles\(ForwardRef\(TextField\)\)-root-31 .MuiInput-underline:before {
  opacity: 1;
  border-bottom-color: #ffffff66 !important;
}

.MuiInputLabel-animated {
  transition: color 200ms cubic-bezier(0, 0, 0.2, 1) 0ms, transform 200ms cubic-bezier(0, 0, 0.2, 1) 0ms;
  text-align: left;
  font: normal normal medium 12px/22px Lato;
  letter-spacing: 0.6px;
  color: #ffffff66 !important;
  text-transform: uppercase;
  opacity: 1;
  font-size: small;
}

.MuiInput-underline:before {
  border-bottom: 1px solid #ffffff66 !important;
}

.MuiInput-underline:after {
  border-bottom: 1px solid #ffffff66 !important;
}

.WithStyles\(ForwardRef\(TextField\)\)-root-31 label {
  font: normal normal medium 12px/22px Lato;
  color: #ffffff66;
  opacity: 1;
  font-size: small;
  text-align: left;
  letter-spacing: 0.6px;
  opacity: 1;
}

.MuiInputBase-root .MuiInput-root .MuiInput-underline .MuiInputBase-formControl .MuiInput-formControl {
  border-bottom: 1px solid #ffffff66 !important;
}

.MuiFormLabel-root .Mui-focused {
  color: #ffffff66 !important;
  opacity: 1;
}

.MuiInput-underline .Mui-focused {
  border-bottom: 1px solid #ffffff66 !important;
}

.MuiFormLabel-root .MuiInputLabel-root .MuiInputLabel-formControl .MuiInputLabel-animated {
  font-size: small !important;
}

.MuiSelect-select.MuiSelect-select {
  font: normal normal medium 12px/22px Lato;
  color: #ffffff66;
  opacity: 1;
  text-align: left;
  letter-spacing: 0.6px;
  opacity: 1;
}

.MuiFormControl-root {
  width: 100%;
}

.MuiFormLabel-root.Mui-error {
  color: #f44336 !important;
}

.MuiInput-underline .Mui-error {
  border-bottom: 1px solid #f44336 !important;
}

.MuiInput-underline.Mui-error:after {
  transform: scaleX(1);
  border-bottom-color: #f44336 !important;
}

.MuiInputBase-input {
  font-size: smaller !important;
  /* color: #FFFFFF66 !important; */
  opacity: 1;
}

.profileHeading {
  text-align: left;
}

.test {
  height: 90px !important;
  width: 90px !important;
}

.form-control {
  float: inherit;
  width: -webkit-fill-available !important;
  margin-bottom: 25px !important;
  margin-right: 25px !important;
}

.two-button-grid {
  padding: 1%;
  margin-bottom: 25px !important;
}

.update-button {
  background-color: #0099ff !important;
}

.input-label.MuiFormLabel-root.Mui-focused,
.MuiFormLabel-root.MuiInputLabel-root.input-label {
  color: rgba(0, 80, 110, 1) !important;
  font-weight: 700 !important;
}

.input-label + .MuiInput-underline:before,
.input-label + .MuiInput-underline:after {
  border-bottom: 1px solid rgba(0, 80, 110, 1) !important;
}

.MuiInput-input {
  color: black !important;
}

.MuiFormControl-root {
  background: transparent !important;
}

.form-control + .input-field + label + input {
  color: red !important;
}

.text-field .MuiInput-underline:before,
.text-field .MuiInput-underline:after {
  border-bottom: 1px solid rgb(20 188 206 / 42%) !important;
  color: black !important;
}

.text-field .MuiInputBase-input {
  color: black !important;
}

.invalid-feedback {
  color: red;
  float: left;
  align-self: baseline;
  display: block !important;
  padding-bottom: 2px;
}

.logoutBtn {
  color: white !important;
  border-color: white !important;
  margin-right: 1% !important;
  height: '37px';
  margin-top: '5px';
}

.profileAvatar {
  background: white !important;
  color: #002bff !important;
}

.MuiTab-textColorInherit {
  opacity: 1 !important;
}

.devPortalLabel {
  margin-top: 64px;
}

.devPortalHome {
  margin-top: 60px;
}

.card-header-2 {
  color: #0099ff !important;
  padding-bottom: inherit !important;
  opacity: 100% !important;
}

.serviceTabs .MuiTab-root {
  min-width: 50px;
}

/* ------------------------------------------ */

.pt-60 {
  padding-top: 60px;
}

.registration-form {
  color: red;
  text-align: center !important;
  margin-bottom: 10px !important;
}

.login-component {
  background-image: url(./images/with-h-f.svg);
  /* background: red; */
  min-height: 100vh;
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
  position: fixed;
  width: 70%;
  z-index: 99999;
}

.login-component {
  display: none;
}

.keepLoggedIn {
  color: #656565;
  float: left;
  font: normal normal small 12px/15px Lato;
  font-size: small;
}

.forgotPassword {
  color: #ff6e6e;
  text-decoration: underline;
  float: right;
  font: normal normal small 12px/15px Lato;
  font-size: small;
}

.signUpAccount {
  color: #656565;
  float: left;
  font: normal normal small 12px/15px Lato;
  font-size: small;
}

.login-card {
  background-color: transparent !important;
  height: 195px !important;
  width: 45%;
  margin-top: 10%;
  box-shadow: 0px 0px 0px #00000080 !important;
  border: 0.5px solid #ffffff !important;
  opacity: 1 !important;
}

.login-card-header {
  color: #ffffff !important;
  font-size: 22px !important;
  font: normal normal 600 22px/27px Montserrat;
}

.login-card-body {
  letter-spacing: 0.36px !important;
  color: #ffffff !important;
  opacity: 1 !important;
  font-size: 18px !important;
  margin-top: 40px !important;
  font: normal normal normal 18px/22px Montserrat !important;
}

.card-container {
  border-right: solid;
  border-left: solid;
  border-bottom: solid;
  border-width: 1px;
  border-color: #0098ff;
  box-shadow: 0px 3px 3px #00000066;
  margin: 10%;
  margin-bottom: 20%;
}

.card-header-1 {
  padding: 0px !important;
  background: #aae5ff !important;
  font: 'normal normal small 15px/18px Lato' !important;
  font-size: small !important;
  letter-spacing: 0px !important;
  color: #000000 !important;
  height: 100% !important;
  width: 100% !important;
  text-align: center !important;
  border-radius: 100% !important;
  border-top-left-radius: 0 !important;
  border-top-right-radius: 0 !important;
}

.login-form-control {
  float: inherit !important;
  width: 99% !important;
  margin-bottom: 25px !important;
}

/* .form-label {
	padding: 0px !important;
} */

.registration-card {
  background-color: transparent !important;
  height: 80% !important;
  width: 58%;
  margin-top: 10%;
  box-shadow: 0px 0px 0px #00000080 !important;
  border: 1px solid #ffffff !important;
  opacity: 1 !important;
  border-top-left-radius: 20px !important;
  border-bottom-right-radius: 20px !important;
  text-align: center !important;
}

.registration-card-header {
  color: #ffffff !important;
  font-size: 22px !important;
  font: normal normal 600 22px/27px Montserrat;
}

.registration-card-body {
  letter-spacing: 0.36px !important;
  color: #ffffff !important;
  opacity: 1 !important;
  font-size: 18px !important;
  margin-top: 40px !important;
  font: normal normal normal 18px/22px Montserrat !important;
}

.circle {
  padding: 0px !important;
  background: #aae5ff !important;
  color: #000000 !important;
  height: 80px !important;
  width: 80px !important;
  border-radius: 100% !important;
  text-align: center;
  align-content: center;
}

.registration-page-avatar {
  margin-left: 42%;
  height: 70px !important;
  width: 70px !important;
  background-color: #aefffac2 !important;
  margin-top: 4% !important;
}

remember-me {
  color: #656565 !important;
  float: left !important;
  font: normal normal small 12px/15px Lato !important;
  font-size: small !important;
}

.link {
  color: #00b1ff !important;
  margin-left: 10px;
}

.login-button {
  background-color: #ecf9ff !important;
  border: solid !important;
  border-color: #0098ff5c !important;
  border-width: 1px !important;
  /* margin-top: 20px; */
}

.registration-form {
  text-align: center !important;
}

.textAlignCenter {
  text-align: center !important;
}

.input-field .MuiInputBase-input {
  color: black !important;
}

.headerLoginBtn {
  height: 37px !important;
  margin-top: 5px !important;
  color: inherit !important;
  border-color: white !important;
  margin-left: 5px !important;
}

.headerLoginBtn:hover {
  background: #ffffff !important;
  border: 1px solid #0099ff !important;
  text-align: center !important;
  color: #0099ff !important;
}

.headerLoginBtn span {
  color: #ffffff !important;
}

.headerLoginBtn:hover span {
  color: #0099ff !important;
}
.header-logo,
.header_logo {
  display: block !important;
  width: auto !important;
}
.password-icon {
  background: none !important;
  opacity: 0.5;
  transition: none !important;
}
.password-icon:hover,
.password-icon:active,
.password-icon:focus {
  opacity: 0.8;
}
.zkteco-logo,
.zkteco_logo {
  height: 12px;
  margin-left: 5px;
}
.powered_by {
  text-transform: capitalize !important;
  margin: 0;
  line-height: 0;
  font-size: 9px;
  position: relative;
  left: -2px;
  top: 14px;
}
.powered-by {
  text-transform: capitalize !important;
  margin: 0;
  line-height: 0;
  font-size: 9px;
  position: relative;
  left: 180px;
  top: -18px;
}
/* .zkteco-logo {
	height: 12px;
	margin-left: 5px;
} */
/* Media Queries */
@media only screen and (max-width: 1060px) and (min-width: 960px) {
  .zkteco-logo {
    height: 10px;
  }
  .header-logo {
    margin-left: -35px;
    height: 80px !important;
  }
  .powered-by {
    left: 95px;
    top: -14px;
  }
}
@media only screen and (max-width: 500px) and (min-width: 320px) {
  /* .powerd-by{
		font-size: 10px;
	} */
  .powered-by {
    left: 131px;
    top: -14px;
  }
  .zkteco-logo {
    height: 14px;
  }
  .nav-link {
    padding: 0.5rem 0rem !important;
  }
}

@media (min-width: 1280px) and (max-width: 1440px) {
  .login-component {
    background-position: -50px 0;
  }
}

@media (min-width: 1101px) and (max-width: 1279px) {
  .login-component {
    background-position: -250px 0;
    width: 60%;
  }
}

@media (min-width: 960px) and (max-width: 1100px) {
  .login-component {
    background-position: -340px -100px;
    width: 50%;
  }
}

@media (min-width: 600px) and (max-width: 959px) {
  .login-component {
    min-height: 275px;
    background-position: center;
    width: 100%;
    background: #0099ff;
  }
}

@media (min-width: 768px) and (max-width: 959px) {
  .form-area {
    margin-top: 280px !important;
  }
  .register-form-area {
    margin-top: 150px !important;
  }
  .registration-card {
    margin-top: 3% !important;
    margin-left: 2.2%;
    width: 95% !important;
  }
  .login-card {
    margin-top: 3% !important;
    margin-left: 2.2%;
    width: 95% !important;
  }
  .registration-card-body {
    margin-top: 2px !important;
  }
  .registration-card-header .MuiTypography-h5 {
    margin-top: 1% !important;
  }
  .registerStyle .registerStyleItem .registerStyleCardSubHeader {
    margin-top: 1% !important;
  }
  .registration-card .MuiCardContent-root {
    padding: 0 !important;
    padding-top: 10px !important;
  }
  .registration-card .MuiCardContent-root:last-child {
    padding-bottom: 10px !important;
  }
}

@media (min-width: 960px) and (max-width: 1279px) {
  .registration-card {
    margin-left: 20px;
    width: 50% !important;
  }
  .login-card {
    margin-left: 20px;
    width: 50% !important;
  }
}

@media (min-width: 960px) and (max-width: 1110px) {
  .registration-card {
    margin-top: 2% !important;
    width: 48% !important;
  }
}

@media only screen and (min-width: 768px) {
  .login-component {
    display: block;
  }
  /* .powered-by {
		left: 131px;
		top: -14px;
	} */
}

@media only screen and (max-width: 767px) {
  .pt-60 {
    padding-top: 40px !important;
  }
  .registerStyle .registerStyleItem {
    padding-top: 30px !important;
  }
  /* .powered-by {
		left: 180px;
		top: -18px;
	} */
}
/* @media (min-width: 960px) {
	header .MuiTabs-flexContainer {		
		top: 4px;
		position: relative;
	}	
	
} */
/* .MuiTab-textColorInherit.Mui-selected span {
	opacity: 1 !important;
	color:#FFFFFF !important;
} */
.nav-link span {
  opacity: 0.7 !important;
  text-transform: uppercase;
}
.nav-link:hover span,
.nav-link:active span,
.nav-link:focus span {
  opacity: 1 !important;
  color: #ffffff !important;
}
header .MuiTabs-indicator span {
  background: none !important;
}
.headerLinksStyle .MuiTab-textColorInherit.Mui-selected span {
  opacity: 1 !important;
  color: #ffffff !important;
}

.clientCredentialsStyle .MuiGrid-root.container.ApplicationDetailContainer.MuiGrid-grid-xs-8 {
    /* background: #00506E 0% 0% no-repeat padding-box !important; */
    background: #ffffff 0% 0% no-repeat padding-box !important;
    -webkit-box-shadow: 0px 4px 4px #00000054 !important;
    /* box-shadow: 0px 4px 4px #00000054 !important; */
    box-shadow: 0px 3px 4px 2px #00000054 !important;
    border-radius: 0px 0px 3px 3px !important;
    opacity: 1 !important;
    margin-top: 1% !important;
}

.clientCredentialsStyle .MuiGrid-root.container.ApplicationDetailContainer.MuiGrid-grid-xs-8 .clientCredentialsBlock {
    width: 100% !important;
}

.clientCredentialsStyle .MuiGrid-root.container.ApplicationDetailContainer.MuiGrid-grid-xs-8 .MuiGrid-root.MuiGrid-container.MuiGrid-align-items-xs-flex-start label .clientCredentialsClientIdTitle,
.clientCredentialsStyle .MuiGrid-root.container.ApplicationDetailContainer.MuiGrid-grid-xs-8 .MuiGrid-root.MuiGrid-container.MuiGrid-align-items-xs-flex-start label .clientCredentialsClientSecretTitle {
    text-transform: uppercase !important;
    color: #000000 !important;
}

.clientCredentialsStyle .MuiGrid-root.container.ApplicationDetailContainer.MuiGrid-grid-xs-8 .MuiGrid-root.MuiGrid-container.MuiGrid-align-items-xs-flex-start label .clientCredentialsClientIdInfo,
.clientCredentialsStyle .MuiGrid-root.container.ApplicationDetailContainer.MuiGrid-grid-xs-8 .MuiGrid-root.MuiGrid-container.MuiGrid-align-items-xs-flex-start label .clientCredentialsClientSecretInfo {
    word-break: break-word !important;
    color: black !important;
}